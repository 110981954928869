import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import React, { lazy, Suspense } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Loading from "../component/Loading";

const Login = lazy(() => import("../page/Login"));
const Home = lazy(() => import("../page/Home"));
const AboutUs = lazy(() => import("../page/AboutUs"));
const Contact = lazy(() => import("../page/Contact"));
const Career = lazy(() => import("../page/Career"));
const Blog = lazy(() => import("../page/Blog"));
const Product = lazy(() => import("../page/Products"));
const ServicesPage = lazy(() => import("../page/services"));
const NewsCard = lazy(() => import("../page/News"));
const NotFound = React.lazy(() => import("../page/NotFound"));

const pageTransition = {
  in: {
    opacity: 1,
    y: 0,
  },
  out: {
    opacity: 0,
    y: "-20%",
  },
};

const Navigator = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Suspense fallback={<Loading />}>
        <Routes location={location} key={location.pathname}>
          <Route
            path="/login"
            element={
              <motion.div
                initial="out"
                animate="in"
                exit="out"
                variants={pageTransition}
                transition={{ duration: 0.5 }}
              >
                <Login />
              </motion.div>
            }
          />
          <Route
            path="/"
            element={
              <motion.div
                initial="out"
                animate="in"
                exit="out"
                variants={pageTransition}
                transition={{ duration: 0.5 }}
              >
                <Home />
              </motion.div>
            }
          />
          <Route
            path="/home"
            element={
              <motion.div
                initial="out"
                animate="in"
                exit="out"
                variants={pageTransition}
                transition={{ duration: 0.5 }}
              >
                <Home />
              </motion.div>
            }
          />
          <Route
            path="/aboutus"
            element={
              <motion.div
                initial="out"
                animate="in"
                exit="out"
                variants={pageTransition}
                transition={{ duration: 0.5 }}
              >
                <AboutUs />
              </motion.div>
            }
          />
          <Route
            path="/contact"
            element={
              <motion.div
                initial="out"
                animate="in"
                exit="out"
                variants={pageTransition}
                transition={{ duration: 0.5 }}
              >
                <Contact />
              </motion.div>
            }
          />
          <Route
            path="/career"
            element={
              <motion.div
                initial="out"
                animate="in"
                exit="out"
                variants={pageTransition}
                transition={{ duration: 0.5 }}
              >
                <Career />
              </motion.div>
            }
          />
          <Route
            path="/blogs"
            element={
              <motion.div
                initial="out"
                animate="in"
                exit="out"
                variants={pageTransition}
                transition={{ duration: 0.5 }}
              >
                <Blog />
              </motion.div>
            }
          />
          <Route
            path="/products"
            element={
              <motion.div
                initial="out"
                animate="in"
                exit="out"
                variants={pageTransition}
                transition={{ duration: 0.5 }}
              >
                <Product />
              </motion.div>
            }
          />
          <Route
            path="/news"
            element={
              <motion.div
                initial="out"
                animate="in"
                exit="out"
                variants={pageTransition}
                transition={{ duration: 0.5 }}
              >
                <NewsCard />
              </motion.div>
            }
          />
          <Route
            path="/services/:id"
            element={
              <motion.div
                initial="out"
                animate="in"
                exit="out"
                variants={pageTransition}
                transition={{ duration: 0.5 }}
              >
                <ServicesPage />
              </motion.div>
            }
          />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </AnimatePresence>
  );
};

export default Navigator;
